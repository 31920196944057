<template>
  <div id="st-container" class="st-container">
    <NavMenu/>
    <ContentWrapper/>
  </div>
</template>

<script>
export default {
  name: 'Container',
  components: {
    NavMenu: () => import('@/components/menu/NavMenu'),
    ContentWrapper: () => import('@/components/content/ContentWrapper'),
  },
  props: {
    msg: String,
  },
};
</script>

<style>
@media (min-width: 1200px){
#st-container.st-container .container:not(.px-5) {
    max-width: 1500px;
}
}
</style>
